export default {
  dir: "rtl" as "rtl" | "ltr",
  language: "ar" as "en" | "ar" | "tr",
  emptyList: "لا توجد تطبيقات لعرضها",
  og: {
    title: "Appollow - خصومات وعروض مجانية محدودة على تطبيقات الايفون",
    description:
      "ابق على اطلاع على أحدث عروض تطبيقات وألعاب iOS. اعثر على خصومات وعروض مجانية محدودة الوقت على تطبيقاتك المفضلة. اكتشف أفضل التطبيقات والألعاب المتاحة مجانًا لفترة محدودة فقط. أنشئ وشارك قائمتك الخاصة بالتطبيقات المفضلة مع مجتمعنا",
  },
  settings: {
    translateSubmitterNote: "ترجمة ملاحظات الناشر",
    translateSubmitterNoteSub: "الترجمة التلقائية لملاحظات ناشري التطبيقات",
    appearance: "المظهر",
    appearanceSub: "مظهر فاتح، داكن او تلقائي",
    languagePicker: "اللغة",
    languagePickerSub: "اختر لغة التطبيق",
    selectLanguage: "اختر اللغة",
    share: "Appollow شارك",
    shareSub: "نقرة واحدة فقط ونحن نتكفل بالباقي!",
    dark: "داكن",
    light: "فاتح",
    auto: "تلقائي",
    notSet: "غير محدد",
    appAnimation: "المؤثرات الحركية",
    appAnimationSub: "المؤثرات الحركية في التطبيق واثناء التنقل",
    transparency: "الشفافية",
    transparencySub: "تغيير شفافية التطبيق",
    notifications: "الإشعارات",
    notificationsSub: "تفعيل اشعارات التطبيق",
  },
  home: {
    discounted: "تطبيقات مجانية لوقت محدود",
    latestApps: "احدث التطبيقات",
    latestGames: "احدث الالعاب",
    latestAppsAndGames: "احدث التطبيقات والالعاب",
    viewAll: "المزيد",
  },
  navBar: {
    apps: "تطبيقات",
    games: "العاب",
    settings: "الاعدادات",
    home: "الرئيسية",
    submit: "نشر",
    search: "بحث",
    feed: "منشورات",
  },
  footer: {
    p: "كل ما قد تحتاجه",
    bestPlatform: "أفضل منصة لمساعدتك في العثور على تطبيقاتك وألعابك المفضلة",
    copyright: `Appollow © ${new Date().getFullYear()} جميع الحقوق محفوظة`,
  },
  submit: {
    hero: "هنا يمكنك مشاركة التطبيقات و الألعاب ببساطة عن طريق البحث أو إدخال معرف التطبيق وسنتكفل بالباقي من أجلك 😃.",
    search: "اكتب اسم التطبيق أو المعرف أو الرابط ...",
    selectApp: "إختر تطبيقًا",
    selectedApp: "التطبيق المحدد",
    price: "السعر القديم",
    priceSub: "أدخل السعر القديم للتطبيق إذا كنت تعرفه.",
    description: "الوصف *",
    descriptionSub:
      "يمكنك إضافة أي تفاصيل تراها ضرورية حول التطبيق. سيتم إضافة هذا الوصف إلى تفاصيل التطبيق عند نشره. يمكنك كتابة 30 حرفًا على الأقل.",
    descriptionInput: "اكتب شيئًا يوصف التطبيق",
    submit: "إرسال",
    noResult: "لا نتائج",
    noResultSub: "بحثك لم يظهر اي نتائج. حاول استخدام كلمات مختلفة.",
    success: "تم النشر بنجاح",
    error: "حدث خطأ أثناء إرسال التطبيق",
    submittingApp: "جاري نشر التطبيق",
    isSimilar: "هناك وصف مشابه موجود بالفعل لهذا التطبيق",
    loginError: "يجب عليك تسجيل الدخول أولا",
    descriptionTooShort: "الوصف قصير جداً",
  },
  search: {
    hero: "يمكنك البحث هنا عن اسم تطبيق أو وظيفة تطبيق وسنعرض لك أفضل النتائج المطابقة المتاحة",
    search: "ابحث...",
    noResult: "لا نتائج",
    noResultSub: "بحثك لم يظهر اي نتائج. حاول استخدام كلمات مختلفة.",
  },
  apps: {
    hero: "ستجد هنا أحدث التطبيقات المضافة مرتبة حسب وقت إضافتها ، انقر فوق أي تطبيق لفتحه.",
    apps: "تطبيقات",
    games: "العاب",
    all: "الكل",
    latestApps: "احدث التطبيقات",
    latestAppsGames: "احدث التطبيقات والالعاب",
    latestGames: "احدث الالعاب",
  },
  previewApp: {
    free: "مجاني",
    view: "افتح",
    removed: "محذوف",
    description: "وصف التطبيق",
    showMore: "المزيد...",
    showLess: "عرض أقل",
    ageRating: "التصنيف العمري",
    version: "الاصدار",
    size: "الحجم",
    ratingsReviews: "التقييم والمراجعات",
    priceUpdated: "تم تحديث السعر",
    ratings: "التقييمات",
    submitter: {
      submitterNote: "ملاحظة الناشر",
      guest: "ضيف",
      date: {
        seconds: "ثانية",
        minAgo: "قبل دقيقة واحدة",
        minutes: "دقيقة",
        hourAgo: "قبل ساعة واحدة",
        hours: "ساعة",
        yesterday: "البارحة",
        days: "يوم",
        lastWeek: "الأسبوع الماضي",
        weeks: "اسبوع",
        lastMonth: "الشهر الماضي",
        months: "شهر",
        lastYear: "السنة الماضية",
        years: "سنة",
        lastCentury: "القرن الماضي",
        centuries: "قرن",
        ago: "قبل",
        justNow: "الآن",
      },
      seeOriginal: "اعرض الاصل",
      original: "الاصل",
      seeMore: "المزيد",
    },
    screenshots: "لقطات الشاشة",
    dealOver: "انتهى العرض على هذا التطبيق",
    relatedApps: "قد يعجبك ايضاً",
    monthlySubscriptions: "يحتوي اشتراك داخل التطبيق",
    hasInAppPurchases: "يحتوي مشتريات داخل التطبيق",
    totallyFree: "مجاني بالكامل",
    noIAP: "لا يحتوي مشتريات داخل التطبيق",
  },
  shareMenu: {
    shareApp: "شارك التطبيق",
    shareSub:
      "شارك الرابط في وسائل التواصل الاجتماعي أو انقر على نسخ الرابط لمشاركته في مكان آخر.",
    email: "البريد",
    copy: "نسخ الرابط",
    close: "اغلاق",
    other: "أخرى",
  },
  sidebar: {
    guest: "ضيف",
    welcometoAppollow: "مرحبًا بك في Appollow",
    menu: "القائمة",
    welcome: "مرحبًا",
    hot: "HOT",
    signup: "تسجيل حساب",
    login: "تسجيل الدخول",
    logout: "تسجيل الخروج",
    settings: "الاعدادات",
    close: "اغلاق",
    contact: "اتصل بنا",
    aboutUs: "حول",
  },
  offline: {
    title: "غير متصل بالانترنت!",
    body: "يبدو أنك غير متصل بالإنترنت، تآكد من اعدادات الشبكة وتحقق من اتصالك بالانترنت ثم أعد فتح التطبيق.",
    goHome: "الرئيسية",
    contact: "اتصل بنا",
  },
  page404: {
    pageNotFound: "الصفحة غير موجودة",
    pageNotFoundSub: "لايمكن العثور على الصفحة المطلوبة",
    goHome: "الرئيسية",
    contact: "اتصل بنا",
  },
  snackbar: {
    error: "حدث خطأ",
    priceUpdated: "تم تحديث السعر",
    updatingPrice: "جاري تحديث السعر",
    appUpdateError: "حدث خطأ اثناء تحديث معلومات التطبيق",
  },
  contact: {
    hero: "إذا كانت لديك أي أسئلة أو اقتراحات ، فيرجى الاتصال بنا على البريد الالكتروني او وسائل التواصل الاجتماعي",
    followUs: "تابعنا",
    followUsSub:
      "تابعنا على مواقع التواصل الاجتماعي للحصول على أخر الأخبار والتحديثات",
    tapToEmail: "انقر للإرسال",
    name: "الاسم",
    email: "البريد الإلكتروني",
    message: "الرسالة",
    sendMessage: "إرسال",
    required: "مطلوب",
    messageSent: "تم إرسال الرسالة",
    thankYou:
      "شكرا لك على الاتصال بنا، سنقوم بالرد على رسالتك في أقرب وقت ممكن",
    nameError: "يرجى كتابة الاسم الكامل",
    emailError: "البريد الإلكتروني غير صحيح",
    messageError: "يرجى كتابة رسالتك",
    captchaError: "Captcha حدث خطأ اثناء فحص",
  },
  profile: {
    appSubmissions: "تطبيقات منشورة",
    editProfile: "تعديل الملف الشخصي",
    noDescription: "قم باضافة وصف خاص بك",
    noApps: "لم يتم نشر أي تطبيقات حتى الآن",
    submitNow: "شارك تطبيقاتك المفضلة الآن",
  },
  editProfile: {
    update: "تحديث",
  },
  pwa: {
    installed: "تم تثبيت Appollow بنجاح!",
    newVersion: "!يوجد تحديث جديد",
    newVersionDescription: "يتوفر إصدار أحدث من التطبيق، هل تريد التحديث؟",
    update: "تحديث",
    addToHomeScreen: "أضف Appollow الى شاشتك الرئيسية",
    addToHomeScreenDescription:
      "قم بتثبيت Appollow على شاشتك الرئيسية، وقم بالوصول إليه تماماً مثل اي تطبيق عادي. افتح قائمة المشاركة في متصفح سفاري وانقر على 'اضافة الى الشاشة الرئيسية'",
    install: "تثبيت",
    later: "لاحقاً",
    done: "تم",
    moreInfo: "المزيد من المعلومات",
  },
  session: {
    welcome: "مرحباً",
    toAppollow: "في Appollow",
    signedOut: "تم تسجيل الخروج!",
  },
  signup: {
    name: "الاسم",
    email: "البريد",
    chooseAPassword: "اختر رمز سري",
    confirmYourPassword: "أكد رمزك السري",
    bioPlaceholder: "أخبرنا شيئًا عنك ، سيكون هذا هو البايو الخاص بك ...",
    required: "مطلوب",
    uploadPhoto: "اختر صورة",
    createAccount: "إنشاء حساب",
    signInWith: "يمكنك ايضاً تسجيل الدخول مباشرة باستخدام",
    alreadyRegistered: "لديك حساب؟ سجل الدخول من هنا",
    fileSizeError: "5MB حجم الملف كبير جدًا، أقصى حجم هو",
    socialCheckbox: "اضف روابط حسابات التواصل الاجتماعي الخاصة بي",
    optional: "اختياري",
    addPhoto: "اضف صورة",
  },
  login: {
    password: "الرمز السري",
    login: "تسجيل الدخول",
    forgot: "نسيت رمز المرور",
    loginRequired: "تسجيل الدخول",
  },
  passwordReset: {
    recover: "استعادة حسابك",
    description:
      "ببساطة ادخل اسم البريد الإلكتروني الذي استخدمته لتسجيل حسابك وسنرسل لك تعليمات استعادة كلمة المرور.",
    send: "إرسال بريد إعادة الضبط",
    emailSent: "تم إرسال البريد الإلكتروني بنجاح",
  },
  article: {
    share: "شارك هذا المقال:",
  },
  filters: {
    filters: "فرز",
    price: "السعر",
    all: "الجميع",
    free: "المجاني فقط",
    discounted: "مجاني لوقت محدود",
    paid: "مدفوع",
    accept: "موافق",
    reset: "إعادة تعيين",
  },
  deleteComment: {
    areYouSure: "هل انت متأكد؟",
    commentWillBeDeleted: "سيتم حذف هذه الملاحظة نهائيًا",
    yes: "نعم",
    cancel: "إلغاء",
  },
  share: {
    freeForLimitedTime: "مجاني لفترة محدودة",
    oldPrice: "السعر القديم:",
  },
  about: {
    subheader: "أفضل منصة تطبيقات لأجهزة iPhone و iPad",
    description:
      "Appollow هو مجتمع للتطبيقات والألعاب الخاصة بأجهزة Apple المحمولة مثل iPhone و iPad، ونحن نهدف إلى تقديم التطبيقات التي يهتم بها المستخدمون ومساعدتهم على البحث عن التطبيقات والحصول على خصومات وتطبيقات وألعاب مجانية لمدة محدودة على أجهزة iOS",
    heading3: "تم بناء Appollow بجودة عالية",
    heading3sub:
      "تم تصميم Appollow بأحدث تقنيات الويب وهو يمهد الطريق لتطبيقات الويب التقدمية (PWA) التي سيكون لها مستقبل مشرق على أجهزة iOS.",
    about1: "تصميم \n عصري",
    about2: "جودة \n عالية",
    about3: "تطبيقات جديدة \n كل يوم",
    about4: "افضل تقنيات \n الويب",
    features: "المزايا",
    featuresSub:
      "تم تصميم Appollow لتبسيط الطريقة التي تبحث بها عن التطبيقات، مع التركيز على الفوائد.",
    feature1: "اكتشف وابحث عن التطبيقات",
    feature1sub:
      "اكتشف التطبيقات والألعاب المجانية ذات الوقت المحدود ، وابحث عن التطبيقات بسهولة.",
    feature2: "تصميم جميل وعصري",
    feature2sub:
      "Appollow مبني على تقنية PWA، ويمكنك تثبيته على شاشتك الرئيسية واستخدامه تمامًا مثل التطبيق العادي دون الحاجة إلى تنزيل أي شيء.",
    feature3: "شارك تطبيقات",
    feature3sub:
      "يمكن للمستخدمين نشر ومشاركة التطبيقات التي يستخدمونها ويحبونها. إنشئ حسابك واعرض تطبيقاتك المفضلة.",
    builtBy: "تم التطوير بواسطة",
    sponsoredBy: "برعاية",
  },
  notifications: {
    title: "تفعيل الإشعارات",
    enable: "تفعيل",
    description:
      "قم بتفعيل الإشعارات حتى لا تفوتك أحدث التطبيقات والألعاب! \n(يتطلب نظام iOS 16.4 او احدث)",
    enableDenied: "الرجاء تمكين الاشعارات من اعدادات جهازك.",
    success: "تم تفعيل الاشعارات بنجاح",
    successMsg: "ستتمكن الآن من تلقي إشعارات من Appollow",
    limitedTimeFree: "مجاني لفترة!",
    installPWAFirst: "يرجى تثبيت تطبيق Appollow على الشاشة الرئيسية اولاً",
    new: "جديد!",
    updateTo164:
      "الرجاء تحديث جهازك الى iOS 16.4 أو أحدث أولاً لتفعيل ميزة الاشعارات",
    prev: "سابقاً",
    how: "كيف؟",
  },
  feed: {
    feed: "منشورات",
    write: "اكتب عن تطبيق...",
    share: "نشر",
    errorReacting: "حدث خطأ أثناء التفاعل",
    like: "اعجاب",
    loginToReact: "يجب عليك تسجيل الدخول للتفاعل",
  },
}
