import { AppInterface } from "types/types"
import { create } from "zustand"
import { devtools } from "zustand/middleware"

interface State {
  openSideBar: boolean
  app?: AppInterface
  openAppearanceSheet: boolean
  openLanguageSheet: boolean
  openShareMenu: boolean
  showInstallAppSheet: boolean
  setInstallAppSheet: (value: boolean) => void
  toggleSidebar: () => void
  toggleAppearanceSheet: () => void
  toggleLanguageSheet: () => void
  toggleShareMenu: () => void
  saveAppDetails: (app?: AppInterface) => void
}

const useUnsavedStore = create<State>()(
  devtools((set) => ({
    openSideBar: false,
    openAppearanceSheet: false,
    openLanguageSheet: false,
    openShareMenu: false,
    showInstallAppSheet: false,
    setInstallAppSheet: (value) => set({ showInstallAppSheet: value }),
    toggleSidebar: () =>
      set((state) => ({ ...state, openSideBar: !state.openSideBar })),
    toggleAppearanceSheet: () =>
      set((state) => ({
        ...state,
        openAppearanceSheet: !state.openAppearanceSheet,
      })),
    toggleLanguageSheet: () =>
      set((state) => ({
        ...state,
        openLanguageSheet: !state.openLanguageSheet,
      })),
    toggleShareMenu: () =>
      set((state) => ({ ...state, openShareMenu: !state.openShareMenu })),
    saveAppDetails: (app?: AppInterface) =>
      set((state) => ({ ...state, app: app })),
  })),
)

export default useUnsavedStore
