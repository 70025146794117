import { hotjar } from "react-hotjar"
import { useEffect } from "react"

function HotJar() {
  useEffect(() => {
    hotjar.initialize({
      id: 3355925,
      sv: 6,
    })
  }, [])
  return <></>
}
export default HotJar
