export default {
  dir: "ltr" as "rtl" | "ltr",
  language: "en" as "en" | "ar" | "tr",
  emptyList: "No apps to show",
  og: {
    title: "Appollow - Find Discounts and Limited-Time free iOS apps",
    description:
      "Stay up to date on the latest iOS app and game deals with our website. Find discounts and Limited-Time Free Offers on your favorite apps. Discover the best apps and games available for free, for a limited time only. Create and share your own list of favorite apps with our community",
  },
  settings: {
    translateSubmitterNote: "Translate Submitter's Note",
    translateSubmitterNoteSub: "Automatically Translate Submitter's Note",
    appearance: "Appearance",
    appearanceSub: "Dark mode, Light mode or Auto",
    languagePicker: "Language Picker",
    languagePickerSub: "Select the display language",
    selectLanguage: "Select your language",
    share: "Share Appollow",
    shareSub: "Just one tap! We'll do the rest!",
    dark: "Dark",
    light: "Light",
    auto: "Auto",
    notSet: "Not Set",
    appAnimation: "Animations",
    appAnimationSub: "Enable or disable the animations to improve performance",
    transparency: "Transparency",
    transparencySub: "Toggle the transparency of the app",
    notifications: "Notifications",
    notificationsSub: "Toggle the notifications of the app",
  },
  home: {
    discounted: "Discounted Apps",
    latestApps: "Latest Apps",
    latestGames: "Latest Games",
    latestAppsAndGames: "Latest Apps & Games",
    viewAll: "View All",
  },
  navBar: {
    apps: "Apps",
    games: "Games",
    settings: "Settings",
    home: "Home",
    submit: "Submit",
    search: "Search",
    feed: "feed",
  },
  footer: {
    p: "All what you need!",
    bestPlatform:
      "The best platform to help you find your favorite apps and games",
    copyright: `Copyright © Appollow ${new Date().getFullYear()}. All Rights Reserved.`,
  },
  submit: {
    hero: "Here you can submit apps or games simply by searching or inserting the app id and we will take care of the rest for you 😃.",
    search: "Type app name, id or link...",
    selectApp: "Select App",
    selectedApp: "Selected App",
    price: "Old Price",
    priceSub: "Enter the OLD price of the app if you know it.",
    description: "Description *",
    descriptionSub:
      "You can add any details about the app that you see necessary. This description will be added to the app details when submitted. You should type at least 30 characters.",
    descriptionInput: "Enter your description...",
    submit: "Submit",
    noResult: "No Results",
    noResultSub:
      "Your search brought up no results. Try using a different keyword. Or try typing all to see all items in the demo. These can be linked to anything you want.",
    success: "Submitted successfully!",
    error: "App submission failed!",
    submittingApp: "Submitting app...",
    isSimilar: "There's a similar description already existing for this app",
    loginError: "You should be logged in to submit apps",
    descriptionTooShort: "The description is too short",
  },
  search: {
    hero: "You can search here for an app name or an app functionality and we will show you the best matching available results",
    search: "Search here...",
    noResult: "No Results",
    noResultSub:
      "Your search brought up no results. Try using a different keyword. Or try typing all to see all items in the demo. These can be linked to anything you want.",
  },
  apps: {
    hero: "Here you will find the latest added apps ordered by the time they're added, tap on any app to open it.",
    apps: "Apps",
    games: "Games",
    all: "All",
    latestApps: "Latest Apps",
    latestAppsGames: "Latest Apps & Games",
    latestGames: "Latest Games",
  },
  previewApp: {
    free: "Free",
    view: "view",
    removed: "removed",
    description: "Description",
    showMore: "Show More...",
    showLess: "Show less",
    ageRating: "Age Rating",
    version: "Version",
    size: "Size",
    ratingsReviews: "Rating & Reviews",
    priceUpdated: "Price Updated",
    ratings: "Ratings",
    submitter: {
      submitterNote: "Submitter Note",
      guest: "Guest",
      date: {
        seconds: "Seconds",
        minAgo: "1 minute ago",
        minutes: "minutes",
        hourAgo: "1 hour ago",
        hours: "hours",
        yesterday: "Yesterday",
        days: "days",
        lastWeek: "Last Week",
        weeks: "weeks",
        lastMonth: "Last month",
        months: "months",
        lastYear: "Last year",
        years: "years",
        lastCentury: "Last century",
        centuries: "centuries",
        ago: "ago",
        justNow: "Just now",
      },
      seeOriginal: "See Original",
      original: "Original",
      seeMore: "see more",
    },
    screenshots: "Screenshots",
    dealOver: "Deal is over on this app",
    relatedApps: "Related Apps",
    monthlySubscriptions: "Offers In App Subscriptions",
    hasInAppPurchases: "Offers In App Purchases",
    totallyFree: "Totally Free",
    noIAP: "Doesn't include In App Purchases",
  },
  shareMenu: {
    shareApp: "Share App",
    shareSub:
      "Share the link to social media or simply tap Copy URL to save it to your clipboard.",
    email: "Email",
    copy: "Copy URL",
    close: "Close",
    other: "Other",
  },
  sidebar: {
    guest: "Guest",
    welcometoAppollow: "Welcome to Appollow",
    menu: "Menu",
    welcome: "Welcome",
    hot: "HOT",
    signup: "Sign Up",
    login: "Login",
    logout: "Log out",
    settings: "Settings",
    close: "Close",
    contact: "Contact",
    aboutUs: "About Us",
  },
  offline: {
    title: "Offline!",
    body: "It looks like you are not connected to the internet, check your connection and make sure that you are connected to the internet then re-open the app.",
    goHome: "Back Home",
    contact: "Contact us",
  },
  page404: {
    pageNotFound: "Page Not Found",
    pageNotFoundSub: "The page you are looking for does not exist.",
    goHome: "Back Home",
    contact: "Contact us",
  },
  snackbar: {
    error: "An error occurred",
    updatingPrice: "Updating App Price...",
    priceUpdated: "App Price Updated!",
    appUpdateError: "Error updating app information",
  },
  contact: {
    hero: "If you have any questions or suggestions, please contact us using the following form",
    followUs: "Follow Us",
    followUsSub:
      "Follow us on social media! We are always open to new ideas and suggestions. Feel free to contact us!",
    tapToEmail: "Tap to email",
    name: "Name",
    email: "Email",
    message: "Message",
    sendMessage: "Send Message",
    required: "required",
    messageSent: "Message Sent!",
    thankYou:
      "Thank you for contacting us, we will get back to you as soon as possible.",
    nameError: "Please enter your name",
    emailError: "Please enter a valid email",
    messageError: "Please enter a message",
    captchaError: "Error while checking captcha!",
  },
  profile: {
    appSubmissions: "App Submissions",
    editProfile: "Edit Profile",
    noDescription: "Add a description for your profile",
    noApps: "No apps submitted yet",
    submitNow: "Submit your favorite apps now",
  },
  editProfile: {
    update: "Update",
  },
  pwa: {
    installed: "Appollow installed successfully!",
    newVersion: "New Version Available!",
    newVersionDescription:
      "A newer version of Appollow is available, reload to update?",
    update: "Update",
    addToHomeScreen: "Add Appollow to your Home Screen",
    addToHomeScreenDescription:
      "Install Appollow on your home screen, and access it just like a regular app. Open your Safari menu and tap 'Add to Home Screen'.",
    install: "Install",
    later: "Maybe later",
    done: "Done",
    moreInfo: "More Info",
  },
  session: {
    welcome: "Welcome",
    toAppollow: "to Appollow",
    signedOut: "You have been signed out!",
  },
  signup: {
    name: "Name",
    email: "Email",
    chooseAPassword: "Choose a Password",
    confirmYourPassword: "Confirm your Password",
    bioPlaceholder: "tell us something about you, that will be your Bio...",
    required: "required",
    uploadPhoto: "Upload Profile Photo",
    createAccount: "Create Account",
    signInWith: "or sign in directly with",
    alreadyRegistered: "Already Registered? Log in Here",
    fileSizeError: "File size is too big, the max file size is 5 MB",
    socialCheckbox: "Add my social media links",
    optional: "optional",
    addPhoto: "add photo",
  },
  login: {
    password: "Password",
    login: "Login",
    forgot: "Forgot Credentials",
    loginRequired: "login required",
  },
  passwordReset: {
    recover: "Recover your Account",
    description:
      "Simply enter your email name which you registered your account under and we'll send you the password reset instructions.",
    send: "Send Reset Instructions",
    emailSent: "Email Sent Successfully",
  },
  article: {
    share: "Share this article:",
  },
  filters: {
    filters: "Filters",
    price: "Price",
    all: "All Apps",
    free: "Free Apps",
    discounted: "Discounted",
    paid: "Paid Apps",
    accept: "Accept",
    reset: "Reset",
  },
  deleteComment: {
    areYouSure: "Are you sure?",
    commentWillBeDeleted: "This comment will be deleted permanently",
    yes: "Yes",
    cancel: "Cancel",
  },
  share: {
    freeForLimitedTime: "Free for a limited time",
    oldPrice: "Old price:",
  },
  about: {
    subheader: "The Best apps platform for iPhone and iPad",
    description:
      "Appollow is a community for apps and games specific to apple mobile devices like iPhones and iPads, We aim to deliver the apps that users care about and make it intuitive and easy to search for apps and get discounts and limited time free apps and games for iOS devices",
    heading3: "Appollow is built for quality",
    heading3sub:
      "Appollow is built with the latest web technologies and it paves the road for progressive web apps (PWA) that will has a bright future on iOS devices.",
    about1: "Modern Design \n App",
    about2: "Top \n Quality",
    about3: "Daily New \n Content",
    about4: "Best Web \n Technologies",
    features: "Features",
    featuresSub:
      "Appollow is designed to simplify the way you look for apps, with focus on benefit.",
    feature1: "Discover and search for apps",
    feature1sub:
      "Discover limited time free apps and games, and search for apps easily.",
    feature2: "Beautiful Modern Design",
    feature2sub:
      "Appollow is built wi PWA technology, and you can install it on your home screen and use it just like a normal app without having to download anything.",
    feature3: "Publish your Apps",
    feature3sub:
      "Users can submit and share apps they use and love. create a profile and showcase your favorite apps.",
    builtBy: "Appollow is built by",
    sponsoredBy: "and Sponsored by",
  },
  notifications: {
    title: "Enable Notifications",
    enable: "enable",
    description:
      "Enable the notifications so you don't miss out on the latest apps and games!\n (iOS 16.4 or later required)",
    enableDenied: "Please enable the notifications from your device settings",
    success: "Notifications enabled",
    successMsg: "You'll now be able to receive notifications from Appollow",
    limitedTimeFree: "Limited time free!",
    installPWAFirst: "Please install Appollow on your home screen first.",
    new: "New!",
    updateTo164: "Please update your iOS device to iOS 16.4 or later first",
    prev: "Previously",
    how: "How?",
  },
  feed: {
    feed: "Feed",
    write: "write about an app...",
    share: "Share",
    errorReacting: "Error reacting to the post",
    like: "Like",
    dislike: "Dislike",
    loginToReact: "You need to login to react to posts",
  },
}
