import { motion } from "framer-motion"
import { ReactNode } from "node_modules/@types/react"
import useGlobalStore from "stores/global"

interface Props {
  children: ReactNode
  onClick: () => void
  className: string
}

const Backdrop = ({ children, onClick, className }: Props) => {
  const animationToggle = useGlobalStore((state) => state.animationToggle)
  return (
    <motion.div
      onClick={onClick}
      className={className}
      initial={animationToggle && { opacity: 0 }}
      animate={animationToggle && { opacity: 1 }}
      exit={animationToggle ? { opacity: 0 } : undefined}
      transition={animationToggle ? { duration: 0.15 } : undefined}
    >
      {children}
    </motion.div>
  )
}

export default Backdrop
