import {
  AppInterface,
  Price,
  SocialLinks,
  submitter,
  translatedDescription,
} from "./types/types"
const dev = process.env.NODE_ENV !== "production"

export const server = dev
  ? "http://localhost:3000"
  : process.env.NEXT_PUBLIC_SERVER

interface FetchProps {
  pageParam: unknown
  price?: Price | ""
}

export const fetchInitials = async () => {
  const res = await fetch(`${server}/api/initialApps`)
  return await res.json()
}
export const fetchApps = async ({ pageParam = 0, price = "" }: FetchProps) => {
  const res = await fetch(`${server}/api/apps?page=${pageParam}&price=${price}`)
  return await res.json()
}
export const fetchGames = async ({ pageParam = 0, price = "" }: FetchProps) => {
  const res = await fetch(
    `${server}/api/games?page=${pageParam}&price=${price}`,
  )
  return await res.json()
}
export const fetchAll = async ({ pageParam = 0, price = "" }: FetchProps) => {
  const res = await fetch(
    `${server}/api/allApps?page=${pageParam}&price=${price}`,
  )
  return await res.json()
}
export const updatePriceFn = async (body: {
  currentPrice?: number | "$$$"
  trackId?: number
  trackName?: string
  lastSubmitter?: submitter
  itunesAppItem: AppInterface
  appRemoved?: boolean
  translations?: translatedDescription[]
  notify?: boolean
}) => {
  const res = await fetch(`${server}/api/updatePrice`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
  return await res.json()
}

export const fetchPrice = async (
  id: string | string[] | number,
  url?: string | string[],
) => {
  const res = await fetch(
    `${server}/api/checkPrice?url=${encodeURIComponent(
      url as string,
    )}&id=${id}`,
  )
  return await res.json()
}
export const fetchApp = async (id: string | string[] | number) => {
  const res = await fetch(`${server}/api/app?id=${id}`)
  return await res.json()
}
export const fetchProfile = async (id: string | string[]) => {
  const res = await fetch(`${server}/api/profile?id=${id}`)
  return await res.json()
}

export const submitMutation = async ({
  method = "POST",
  submitterDescription,
  submittedApp,
  submitterPrice,
  displayName,
  photoURL,
  uid,
  other,
  token,
}: {
  method?: string
  submittedApp?: AppInterface
  submitterPrice?: number | string
  displayName?: string
  photoURL?: string
  uid?: string
  submitterDescription?: string
  other?: any
  token?: string
}) => {
  const translation =
    submitterDescription &&
    (await fetch(
      `${server}/api/translate?text=${submitterDescription.replace(
        /\n/g,
        "<br>",
      )}`,
    ).then((res) => res.json()))

  const res = await fetch(`${server}/api/submit`, {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      ...submittedApp,
      submitterPrice,
      submitter: {
        displayName,
        photoURL,
        uid,
        other,
        submitterDescription,
        translatedDescription:
          submitterDescription &&
          translation?.[0]?.translations.map((item: translatedDescription) => ({
            ...item,
            text: item.text.replace(/<br>/g, "\n"),
          })),
      },
    }),
  })

  return await res.json()
}

interface UserData {
  uid: string
  accessToken?: string
  photoURL?: string | null
  displayName?: string | null
  bio?: string
  email?: string | null
  links?: SocialLinks
}
export const updateUserMutation = async ({
  photoURL,
  email,
  uid,
  displayName,
  bio,
  accessToken,
  links,
}: UserData) => {
  const res = await fetch(`${server}/api/updateUser`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      photoURL,
      uid,
      email,
      displayName,
      bio,
      accessToken,
      links,
    }),
  })
  return await res.json()
}
